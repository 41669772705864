import React, { useState } from "react";
import { LOADING, SHOW_MODAL } from "../Redux/types";
import { findBet } from "../Services/apis";
import ViewCoupon from "./Modal/ViewCoupon";

export const CouponCheckHome = ({ dispatch }) => {
  const [couponCode, setCouponCode] = useState("");

  const showCoupon = () => {
    if (couponCode.length) {
      dispatch({ type: LOADING });
      findBet({
        betslipId: couponCode,
        clientId: process.env.REACT_APP_CLIENT_ID,
      })
        .then((res) => {
          dispatch({ type: LOADING });
          if (res.success) {
            setCouponCode("");
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: true,
                title: "Betslip " + res.data.betslipId,
                component: (
                  <ViewCoupon betslip={res.data} dispatch={dispatch} />
                ),
              },
            });
          } else {
            alert("Coupon does not exist");
          }
        })
        .catch((err) => dispatch({ type: LOADING }));
    }
  };

  return (
    <div className="check-coupon">
      <div className="SXTitle">
        <a>Coupon Check</a>
      </div>
      <div className="divCouponCheck">
        <div>
          <div className="CheckCouponMain">
            <div className="CheckCpnTxt">
              Insert the coupon code you wish to check
            </div>
            <input
              name="couponCode"
              type="text"
              onKeyPress={(e) => {
                if (e.key === "Enter") showCoupon();
              }}
              autoComplete="off"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder="Enter coupon code"
            />
            <button className="btnCheckCoupon" onClick={showCoupon}>
              Check
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
