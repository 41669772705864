import React, {useState} from 'react';
import {LOADING} from "../Redux/types";
import { SHOW_MODAL } from '../Redux/types';
import { findBet } from '../Services/apis';
import ViewCoupon from './Modal/ViewCoupon';

export default function CouponCheck({ dispatch }) {
    const [couponCode, setCouponCode] = useState('');

    const showCoupon = (e) => {
        e.preventDefault();

        if (couponCode.length) {
            dispatch({type: LOADING});
            findBet({
                betslipId: couponCode,
                clientId: process.env.REACT_APP_CLIENT_ID
            }).then(res => {
                dispatch({type: LOADING});
                if (res.success) {
                    setCouponCode('')
                    dispatch({type: SHOW_MODAL, payload: {
                        open: true,
                        title: 'Betslip ' + res.data.betslipId,
                        component: <ViewCoupon betslip={res.data} dispatch={dispatch} />
                    }});
                } else {
                    alert('Coupon does not exist');
                }
            }).catch(err => dispatch({type: LOADING}))
        }
    }

    return (
        <div className="divCouponCheck">

            <div>
        
                <div className="CheckCouponMain">
                    <div className="CheckCpnTxt">
                        Insert the coupon code you wish to check
                    </div>
                    <input 
                        name="s$w$PC$ctl06$txtCodiceCoupon" 
                        type="text"
                        onKeyUp={showCoupon} 
                        autoComplete="off"
                        id="verifyCouponId"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        onFocus={(e) => setCouponCode('')}
                    />
                    <a className="btnCheckCoupon" onClick={showCoupon} title="Check" href="#"></a>
                </div>
            </div>
        </div>
    )
}
