import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSports, setTournaments } from "../Redux/actions";
import { SET_TOURNAMENTS } from "../Redux/types";
import { getCategories, getTournaments } from "../Services/apis";

export const SportsMenu = ({sports, tournaments, activePeriod}) => {
  
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleSportMenu = async (e, type, item, sindex, catIndex) => {
    e.currentTarget.parentNode.classList.toggle("sel");
    const ele = e.currentTarget.parentNode;

    if (ele.classList.contains('sel')) {
      if (type === 'categories' && !item.categories) {
        await getCategories(activePeriod, item.sportID).then((res) => {
          if (res.sports.length) {
            sports[sindex].categories = res.sports;
            dispatch(setSports(sports));
          }
        });
      } 
      if (type === 'tournaments' && !item.tournaments) {
        await getTournaments(activePeriod, item.categoryID).then((res) => {
          if (res.sports.length) {
            sports[sindex].categories[catIndex].tournaments = res.sports;
            dispatch(setSports(sports));
          }
        });
      }
    }
  };

  const openEvent = (cid, tid, sid) => {
    const index = tournaments.findIndex(
      (tournament) => tournament.sport_tournament_id === tid
    );
    if (index !== -1) {
      // remove tournament
      tournaments.splice(index, 1);
      // update state
      dispatch({
        type: SET_TOURNAMENTS,
        payload: tournaments,
      });
    } else {
      const pathname = window.location.pathname;
      const urlSearch = window.location.search;
      const url = `/Sport/Odds?tid=${tid}&sid=${sid}&period=${activePeriod}`;
      if (pathname + urlSearch === url) {
        dispatch(setTournaments({ tid, sid }));
      } else {
        history.push(url);
      }
    }
    // dispatch(setTournaments({tid, sid}));
  };

  return (
    <div>
      <div className="sportPanel">
        <div className="menuContainer">
          <a className="liveButton" href="/Live/LiveDefault">
            <span>LiveBetting</span>
          </a>
          <ul className="sportMenu">
            {sports && sports?.map((sport, sIndex) => (
              <li className="itemSport" key={sport.sportID}>
                <div className="head" onClick={(e) => toggleSportMenu(e, 'categories', sport, sIndex)}>
                  <div className="nameSport">{sport.sportName} </div>
                  <span className="sportCount">{sport.total}</span>
                  <div className="groupLink"></div>
                </div>
                {sport.categories && (
                  <ul className="groupMenu">
                    {sport.categories.map((category, cIndex) => (
                      <li
                        className="itemGroup"
                        key={`category_${category.categoryID}`}
                      >
                        <div
                          className="head"
                          onClick={(e) => toggleSportMenu(e, 'tournaments', category, sIndex, cIndex)}
                        >
                          <div className="image"></div>
                          <div className="nameGroup ">{category.categoryName}</div>
                          <span className="groupCount ">{category.total}</span>
                        </div>
                        {category.tournaments && (
                          <ul className="eventMenu">
                            {category.tournaments.map((tournament) => (
                              <li
                                className={`itemEvent ${
                                  tournaments.some(
                                    (item) =>
                                      item.tournamentID ===
                                      tournament.tournamentID
                                  )
                                    ? "sel"
                                    : ""
                                }`}
                                key={`tournament_${tournament.tournamentID}`}
                                id={`TOR_${tournament.tournamentID}`}
                                onClick={(e) =>
                                  openEvent(
                                    category.categoryID,
                                    tournament.tournamentID,
                                    sport.sportID
                                  )
                                }
                              >
                                <div className="head">
                                  <span className="nameEvent ">
                                    {tournament.tournamentName}
                                  </span>
                                  <span className="eventCount ">
                                    {tournament.total}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
