import React, { Fragment, useEffect, useState } from "react";
import {
  formatDate,
  getOdds,
  isSelected,
  sortOdds,
  toggleExtraMarket,
} from "../Utils/helpers";
import { createID } from "../Utils/couponHelpers";
import { addToCoupon, removeSelection } from "../Redux/actions";
import ViewFixtureModal from "./Modal/ViewFixtureModal";
import { getEventDetails } from "../Services/apis";
export default function FixturesList({
  coupon,
  dispatch,
  predictions,
  fixtures,
  selectedMarkets,
  history,
}) {
  const [showFixture, setShowFixture] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const openWidget = (fixture, widgetName) => {
    const statWrapper = document.getElementById(
      `${widgetName}_${fixture.matchID}`
    );
    statWrapper.parentElement.classList.toggle("brClosed");

    if (statWrapper.innerHTML === "") {
      statWrapper.innerHTML = "Loading...";
      statWrapper.style.color = "#000";
      statWrapper.style.backgroundColor = "#fff";

      window.SRLive.addWidget(`widgets.${widgetName}`, {
        matchId: fixture.matchID,
        showTitle: !1,
        container: `#${widgetName}_${fixture.matchID}`,
      });
    } else {
      statWrapper.innerHTML = "";
    }
  };
  const fetchEvent = async (id) => {
    try {
      const res = await getEventDetails(id);
      res && setSelectedOption(res);
      res && setShowFixture(true);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  return (
    <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
      <tbody>
        {fixtures?.map((fixture, f) => (
          <Fragment key={`fixture-group-${f}`}>
            <tr className="dgHeader" key={`fixtures-${f}`}>
              <td colSpan="4">
                <table cellPadding="0" cellSpacing="0" width="100%">
                  <tbody>
                    <tr>
                      <td colSpan="4" className="cqDateTbl">
                        {formatDate(fixture.event_date, "DD MMM YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td className="codTbl">ID</td>
                      <td className="dataTbl">Time</td>
                      <td className="incTbl" width="100%">
                        Events
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table
                  className="OddsQuotaHeaderStyle"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr>
                      {selectedMarkets?.map((market, m) => (
                        <td
                          key={`market-${m}`}
                          className="cqTbl"
                          colspan={market.outcomes.length}
                        >
                          {market.marketName}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {predictions?.map((pr, p) => (
                        <td
                          className="qtTbl_1"
                          key={`predictions-${p}`}
                          style={{ textAlign: "center" }}
                        >
                          {pr.displayName}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <div className="cqTbl">&nbsp;</div>
                <div className="altreTbl">&nbsp;</div>
              </td>
            </tr>
            {fixture.events?.map((event, i) => (
              <tr key={event.matchID} className="dgAItem">
                <td className="OddsDetailsClassiQuota Stats">
                  <a
                    href="javascript:;"
                    onClick={() => openWidget(event, "matchhead2head")}
                  ></a>
                </td>
                <td className="codInc">{event.gameID}</td>
                <td className="dtInc">{formatDate(event.date, "HH:mm")}</td>
                <td className="nmInc">
                  <span style={{ float: "left" }}>
                    {event.homeTeam} - {event.awayTeam}
                  </span>
                </td>
                <td className="OddsDetailsQuote">
                  <table
                    className="dgQuote"
                    cellPadding={0}
                    cellSpacing={0}
                    border={0}
                    style={{
                      borderWidth: "0px",
                      borderStyle: "None",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr className="OddsQuotaItemStyle">
                        {sortOdds(event?.outcomes).map((outcome, o) => {
                          const id = createID(
                            event.matchID,
                            outcome.marketID,
                            outcome.outcomeName,
                            outcome.outcomeID
                          );
                          return (
                            <td
                              style={{
                                background: isSelected(id, coupon)
                                  ? "#FCDE02"
                                  : "#10589d",
                              }}
                              className={`OddGB_1_0
                              ${isSelected(id, coupon) ? "QuotaSel" : ""}
                            `}
                              key={`odds-${outcome.outcomeID}-${event.matchID}`}
                            >
                              <div className="oddsQ">
                                <a
                                  style={{
                                    background: isSelected(id, coupon)
                                      ? "#FCDE02"
                                      : "#10589d",
                                    borderTop: isSelected(id, coupon)
                                      ? "#FCDE02"
                                      : "#10589d",
                                    color: isSelected(id, coupon)
                                      ? "black"
                                      : "white",
                                  }}
                                  href="javascript:;"
                                  onClick={() =>
                                    isSelected(id, coupon)
                                      ? dispatch(removeSelection(id, "click"))
                                      : dispatch(
                                          addToCoupon(
                                            event,
                                            outcome,
                                            id,
                                            "prematch"
                                          )
                                        )
                                  }
                                >
                                  {outcome.odds.toFixed(2)}
                                </a>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  className="OddsDetailsClassiQuota All"
                  onClick={() => {
                    fetchEvent(event.matchID);
                  }}
                >
                  <a href="javascript: void(0);">
                    <img src="/img/otherOdds.gif" alt="More" border="0" />
                  </a>
                </td>
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
      {showFixture && (
        <ViewFixtureModal
          fixture={selectedOption}
          setShow={() => setShowFixture(false)}
          dispatch={dispatch}
        />
      )}
    </table>
    // <>
    //   {fixtures.map((fixture, f) => (
    //     <div
    //       className={`item ${checkFixtureDate(fixture, f)}`}
    //       key={`fixture-${f}`}
    //     >
    //       <div className="sepData">
    //         {formatDate(fixture.date, "DD MMMM YYYY")}
    //       </div>
    //       <div className="ID">{fixture.gameID}</div>
    //       <div className="Time">
    //         <span>{fixture.eventTime}</span>
    //         <span>{formatDate(fixture.date, "DD MMM")}</span>
    //       </div>

    //       <div
    //         className="Event"
    //         onMouseEnter={() =>
    //           toggleExtraMarket(`extra-market-${fixture.gameID}`)
    //         }
    //         onMouseLeave={() =>
    //           toggleExtraMarket(`extra-market-${fixture.gameID}`)
    //         }
    //         onClick={() =>
    //           history.push(`/Sport/EventDetail?EventID=${fixture.matchID}`)
    //         }
    //       >
    //         {fixture.name}
    //       </div>

    //       {/*<div className="stats innprojekt" ng-if="(subEvent.StatCode != '')"*/}
    //       {/*     ng-click="subEvent.openStatistics()"></div>*/}

    //       <div className="addedFunction">
    //         <div
    //           className="brStarted headToHead"
    //           onClick={(e) => openWidget(fixture, "matchhead2head")}
    //         ></div>
    //         <div
    //           className="brStarted tableLeague"
    //           onClick={() =>
    //             window.open(
    //               `https://s5.sir.sportradar.com/betradar/en/match/${fixture.matchID}`,
    //               "stats",
    //               "width=1078,height=768"
    //             )
    //           }
    //         ></div>
    //       </div>
    //       <div className="odds">
    //         <div className="gq gqid_">
    //           <div className="ng-isolate-scope">
    //             {selectedMarkets?.map((market, i) => (
    //               <div className={`cq t${i}`} key={`market-${i}`}>
    //                 <span>{market.name}</span>
    //                 {getPredictions(market).map((prediction, p) => (
    //                   <button
    //                     className={`odd r1 c1 g1
    //                                             ${
    //                                               predictions.length > 9 &&
    //                                               p % 6 === 0
    //                                                 ? "firstInRow"
    //                                                 : ""
    //                                             }
    //                                             ${
    //                                               getOdds(
    //                                                 prediction,
    //                                                 fixture.odds
    //                                               ) === "-" ||
    //                                               getOdds(
    //                                                 prediction,
    //                                                 fixture.odds
    //                                               ) == null
    //                                                 ? "disabled"
    //                                                 : ""
    //                                             }
    //                                             ${
    //                                               isSelected(
    //                                                 createID(
    //                                                   fixture.provider_id,
    //                                                   prediction.market_id,
    //                                                   prediction.odd_name,
    //                                                   prediction.odd_id
    //                                                 ),
    //                                                 coupon
    //                                               )
    //                                                 ? "sel"
    //                                                 : ""
    //                                             }`}
    //                     key={`prediction-${p}`}
    //                     onClick={() =>
    //                       dispatch(
    //                         addToCoupon(
    //                           fixture,
    //                           prediction.market_id,
    //                           prediction.market_name,
    //                           getOdds(prediction, fixture.odds),
    //                           prediction.odd_id,
    //                           prediction.odd_name,
    //                           createID(
    //                             fixture.provider_id,
    //                             prediction.market_id,
    //                             prediction.odd_name,
    //                             prediction.odd_id
    //                           ),
    //                           fixture.fixture_type
    //                         )
    //                       )
    //                     }
    //                     disabled={
    //                       getOdds(prediction, fixture.odds) < 1.0
    //                         ? true
    //                         : false
    //                     }
    //                     style={{ cursor: "pointer" }}
    //                   >
    //                     <div className="oddsType" title="1">
    //                       {prediction.odd_name}
    //                     </div>
    //                     <div>
    //                       {getOdds(prediction, fixture.odds) <= 1.0
    //                         ? "-"
    //                         : getOdds(prediction, fixture.odds)}
    //                     </div>
    //                   </button>
    //                 ))}
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </>
  );
}
