/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { cancelTicket, getAgentBetList, getBetList } from "../../Services/apis";
import { formatDate, formatNumber } from "../../Utils/helpers";
import BetListFilter from "../../Components/BetListFilter";
import BetListOutcome from "../../Components/BetListOutcome";
import Pagination from "../../Components/Pagination";
import { LOADING } from "../../Redux/types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ViewCoupon from "../../Components/Modal/ViewCoupon";
import { SHOW_MODAL } from "../../Redux/types";

/**
 * packages
 */

export const BetList = ({ match, history }) => {
  const [isCancel, setCancel] = useState({ show: false, ticketId: "" });
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState({
    from: moment().subtract(1, "w").toDate(),
    to: moment().toDate(),
    period: "",
    status: "",
    betslipId: "",
    page_size: 15,
  });

  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 2,
    from: 1,
    to: 0,
    current_page: 1,
    last_page: 0,
  });

  const [totalStake, setTotalStake] = useState(0);
  const [totalWinnings, setTotalWinnings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bets, setBets] = useState([]);

  const fetchBetList = (page) => {
    setLoading(true);

    const data = {
      type: filterData.type,
      from: moment(filterData.from).startOf("day").format("YYYY-MM-DD HH:mm"),
      to: moment(filterData.to).endOf("day").format("YYYY-MM-DD HH:mm"),
      p: filterData.page_size,
      betslipId: filterData.betslipId,
      status: filterData.status,
      userId: user?.id,
      clientId: process.env.REACT_APP_CLIENT_ID,
    };

    if (user.role === "Cashier") {
      cashierBetList(data, page);
    } else {
      agentBetList(data, page);
    }
  };

  const cashierBetList = (data, page) => {
    getBetList(data, page)
      .then((res) => {
        // console.log(res)
        if (res.bets) {
          setPagination({
            total: res.totalRecords,
            per_page: 50,
            from: res.from,
            to: res.to,
            current_page: page,
            last_page: res.lastPage,
          });
          setBets(res.bets);
          setTotalWinnings(res.totalWon);
          setTotalStake(res.totalStake);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const agentBetList = (data, page) => {
    getAgentBetList(data, page)
      .then((res) => {
        // console.log(res)
        if (res.data.tickets.length) {
          const { meta, tickets } = res.data;
          setPagination({
            total: meta.total,
            per_page: meta.perPage,
            from: meta.from,
            to: meta.to,
            current_page: page,
            last_page: meta.lastPage,
          });
          setBets(tickets);
          setTotalWinnings(res.data.totalWon);
          setTotalStake(res.data.totalSales);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.body.classList = "bodyMain en-GB account_master betlist Logged";
  }, []);

  useEffect(() => {
    fetchBetList(1);
  }, []);

  const handleChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const confirmCancel = (ticketId) => {
    setCancel({
      ...isCancel,
      show: true,
      ticketId,
    });
  };

  const doCancel = () => {
    dispatch({ type: LOADING });
    cancelTicket(isCancel.ticketId).then((res) => {
      dispatch({ type: LOADING });
      closeModal();
      if (res.success) {
        toast.success("Ticket has been canceled");
        fetchBetList(pagination.current_page);
      } else {
        toast.error(res.message);
      }
    });
  };

  const closeModal = () => {
    setCancel({ ...isCancel, show: false });
  };

  return (
    <div id="MainContent" className="">
      <div className="Riquadro">
        <div className="TopSX">
          <div className="TopDX">
            <h3>Bet List</h3>
          </div>
        </div>
        <div className="CntSX">
          <div className="CntDX">
            <div className="betlist">
              <BetListFilter
                filterData={filterData}
                handleChange={handleChange}
                fetchResult={fetchBetList}
                ticketsLength={bets.length}
              />
              <div className="divDg">
                {/*<div>
                        <table
                            className="dgStyle" cellSpacing="0" border="0"
                            id="ac_w_PC_PC_BetList_grid"
                            style={{
                                borderWidth:'0px',
                                borderStyle:'None',
                                width: '100%',
                                borderCollapse:'collapse'
                            }}>
                            <tbody>
                            <tr className="dgEmptyStyle">
                                <td colSpan="12">
                                    No record found
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>*/}
                <div>
                  <table
                    className="dgStyle"
                    cellSpacing="0"
                    border="0"
                    id="ac_w_PC_PC_grid"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "None",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr
                        className="dgHdrStyle"
                        style={{
                          borderWidth: "0px",
                          borderStyle: "none",
                          width: "100%",
                          borderCollapse: "collapse",
                          backgroundColor: "#fdd835",
                        }}
                      >
                        <th></th>
                        <th scope="col" style={{ textAlign: "left" }}>
                          Betslip
                        </th>
                        <th scope="col">User</th>
                        <th scope="col">Bet Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Result Date</th>
                        <th className="dgHdrImporti" scope="col">
                          Amount
                        </th>
                        <th scope="col">Outcome</th>
                        <th className="dgHdrImporti" scope="col">
                          Winnings
                        </th>
                      </tr>
                      {loading ? (
                        <tr className="dgItemStyle">
                          <td colSpan="12">Loading...Please wait!</td>
                        </tr>
                      ) : bets.length > 0 ? (
                        bets.map((bet, i) => (
                          <tr className="dgItemStyle" key={bet.betslip_id}>
                            <td style={{ cursor: "pointer" }}>
                              {bet.status === 0 &&
                                bet.active_selections?.length ===
                                  bet.selections.length && (
                                  <img
                                    src="/img/cancel-button.png"
                                    alt=""
                                    onClick={() => confirmCancel(bet.id)}
                                  />
                                )}
                            </td>
                            <td className="btnsec codcoupon">
                              <a
                                title="Dislpay Betslip"
                                href="javascript:;"
                                onClick={() =>
                                  dispatch({
                                    type: SHOW_MODAL,
                                    payload: {
                                      open: true,
                                      title: "Betslip " + bet.betslipId,
                                      component: (
                                        <ViewCoupon
                                          betslip={bet}
                                          dispatch={dispatch}
                                        />
                                      ),
                                    },
                                  })
                                }
                              >
                                {bet.betslipId}
                              </a>
                            </td>
                            <td>{bet.username}</td>
                            <td>{bet.betCategory}</td>
                            <td>
                              {formatDate(bet.created, "DD/MM/YYYY HH:mm:ss")}
                            </td>
                            <td>
                              {bet.settled_at
                                ? formatDate(
                                    bet.settled_at,
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                : ""}
                            </td>
                            <td align="right">{formatNumber(bet.stake)}</td>
                            <td align="center">
                              <BetListOutcome outcome={bet.statusCode} />
                            </td>
                            <td align="right">
                              {bet.statusCode === 0
                                ? formatNumber(bet.possibleWin)
                                : formatNumber(bet.winnings)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="dgEmptyStyle">
                          <td colSpan="12">No record found</td>
                        </tr>
                      )}

                      <tr className="dgTotalsStyle">
                        <td
                          className="btnsec"
                          colSpan="5"
                          style={{ width: "100%" }}
                        >
                          - Total Page -
                        </td>
                        <td
                          className="dgTotalsImpPos"
                          colSpan="2"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {formatNumber(totalStake)}
                        </td>
                        <td colSpan="1"></td>
                        <td
                          className="dgTotalsImpNeg"
                          colSpan="1"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {formatNumber(totalWinnings)}
                        </td>
                        <td colSpan="4"></td>
                      </tr>
                      {/*<tr className="dgTotalsStyle">
                        <td className="btnsec" colSpan="4" style={{width: '100%'}}>- Total -</td>
                        <td className="dgTotalsImpPos" colSpan="2" style={{whiteSpace:'nowrap'}}></td>
                        <td colSpan="1"></td>
                        <td className="dgTotalsImpNeg" colSpan="1" style={{whiteSpace:'nowrap'}}></td>
                        <td colSpan="4"></td>
                    </tr>*/}
                      <Pagination
                        colspan={9}
                        data={pagination}
                        offset={10}
                        changePage={fetchBetList}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isCancel.show && (
          <div className="bet-confirm-popup-wrapper">
            <div className="bet-confirm-popup">
              <div className="close-bet-confirm-popup" onClick={closeModal}>
                <i className="fa fa-times" aria-hidden="true" />
              </div>
              <div className="bet-confirm-content">
                <div className="title">
                  <img src="/img/bet-confirm-info.png" alt="" />
                  <span>
                    Are you sure you want to cancel this ticket?
                    <br />
                    <small style={{ color: "red" }}>
                      Note: You can only cancel tickets within 5 mins after
                      registering the ticket
                    </small>
                  </span>
                </div>
                <div className="buttons">
                  <div className="cancel-button button" onClick={closeModal}>
                    No
                  </div>
                  <div className="confirm-button button" onClick={doCancel}>
                    Yes
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
