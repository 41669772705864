import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { commissionReports, fetchGlobalVars } from "../../Services/apis";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { formatDate } from "../../Utils/helpers";
import CommissionsTable from "../../Components/CommisionsTable";

const Commission = () => {
  const dispatch = useDispatch();
  const [activeTab, setActive] = useState(1);
  const [commissions, setCommissions] = useState([]);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const { user } = useSelector((state) => state.auth);
  const [filterData, setFilterData] = useState({
    from: moment().toDate(),
    to: moment().toDate(),
    page_size: 15,
    period: "today",
    product: "all",
  });

  const isStart = (date) => {
    const day = date.getDay();
    return day === 2;
  };

  const isEnd = (date) => {
    const day = date.getDay();
    return day === 1;
  };

  const fetchResult = (id) => {
    const data = { ...filterData };
    data.from = formatDate(data.from, "YYYY-MM-DD");
    data.to = formatDate(data.to, "YYYY-MM-DD");

    commissionReports(data)
      .then((res) => {
        if (res.success) {
          setCommissions(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err?.response);

        toast.error("Unable to fetch results");
      });
  };

  const handleChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
    if (name === "from" || name === "to") {
      // setFilterData({ ...filterData, period: "custom", [name]: value });
    } else {
      setFilterData({ ...filterData, [name]: value });
    }
  };

  const setDateRange = (e) => {
    const period = e.target.value;
    switch (period) {
      case "today":
        setFilterData({
          ...filterData,
          from: moment().toDate(),
          to: moment().toDate(),
          period,
        });
        break;
      case "yesterday":
        setFilterData({
          ...filterData,
          from: moment().subtract(1, "day").toDate(),
          to: moment().subtract(1, "day").toDate(),
          period,
        });
        break;
      case "custom":
        setFilterData({
          ...filterData,
          period,
        });
        break;
      default:
        break;
    }
    // fetchResult();
  };

  const init = useCallback(() => {
    fetchGlobalVars().then((res) => {});
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt" style={{ marginTop: "-1rem" }}>
                  <h4
                    className="p-4 commission-head bg-pry"
                    style={{
                      borderBottom: ".001px solid white",
                    }}
                  >
                    Report
                  </h4>
                </div>
                <div className="cashbook">
                  <ul className="t-header ">
                    <li
                      style={{ marginLeft: "-2.5rem" }}
                      onClick={() => setActive(1)}
                      className={
                        activeTab === 1 ? `active tab-item ml` : "tab-item ml"
                      }
                    >
                      AGENT COMMISSION
                    </li>
                  </ul>
                  <p
                    className="p-4"
                    style={{
                      background: "white",
                      color: "",
                      marginTop: "-.1px",
                      marginBottom: ".05rem",
                      // borderBottom: ".5px solid red",
                    }}
                  >
                    Use the date flters below to generate the report.
                  </p>
                  <div className="radio">
                    <h3>Select your filter options</h3>
                    <div className="radio-group">
                      <div className="input-group">
                        <input
                          type="radio"
                          name="period"
                          value="today"
                          checked={filterData.period === "today"}
                          onChange={(e) => setDateRange(e)}
                          id="today"
                        />
                        <label htmlFor="today">Last Week</label>
                      </div>

                      <div className="input-group">
                        <input
                          type="radio"
                          name="day"
                          value="custom"
                          checked={filterData.period === "custom"}
                          onChange={(e) => setDateRange(e)}
                          id="custom"
                        />
                        <label htmlFor="Manual">Manual</label>
                      </div>
                    </div>
                  </div>
                  <div className="date-group">
                    <div className="input-group date">
                      <label htmlFor="today">Date From:</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={filterData.from}
                        className="dropdownFiltoData"
                        // maxDate={moment().toDate()}
                        filterDate={isStart}
                        onChange={(date) => handleChange("from", date)}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="today">Date To :</label>{" "}
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        // maxDate={moment().toDate()}
                        selected={filterData.to}
                        filterDate={isEnd}
                        className="dropdownFiltoData"
                        onChange={(date) => handleChange("to", date)}
                      />
                    </div>
                  </div>
                  <div className="proceed">
                    <div className="cancel-btn ">
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: "white",
                        }}
                      />
                      <button className="btn-cancel">Cancel</button>
                    </div>
                    <div
                      className="proceed-btn btn-send"
                      onClick={() => fetchResult(user?.id)}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: "green",
                        }}
                      />
                      <button className="">Proceed</button>
                    </div>
                  </div>
                  {Object.keys(commissions).length > 0 && (
                    <React.Fragment>
                      <CommissionsTable
                        data={commissions}
                        currency={SportsbookGlobalVariable.Currency}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commission;
