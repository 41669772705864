import * as Actions from "../types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as types from "../types";

const initialState = {
  role: [], //guest
  user: {},
  shopUsers: [],
  getUserLoading: false,
  newUserLoading: false,
  isAuthenticated: false,
  access_token: null,
  username: null,
  newUser: {},
};

const userData = persistReducer(
  {
    storage,
    key: "auth",
    whitelist: ["user", "access_token", "isAuthenticated"],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.SET_USER_DATA: {
        return {
          ...state,
          ...action.payload,
        };
      }
      case Actions.UPDATE_USER_DATA: {
        return {
          ...state,
          user: action.payload,
        };
      }
      case types.UPDATE_USERNAME: {
        return {
          ...state,
          username: action.payload,
        };
      }
      case Actions.REMOVE_USER_DATA: {
        return {
          ...initialState,
        };
      }
      case types.UPDATE_USER_BALANCE: {
        return {
          ...state,
          user: {
            ...state.user,
            availableBalance: action.payload,
          },
        };
      }
      case Actions.USER_LOGGED_OUT: {
        return initialState;
      }
      case Actions.GET_USERS_STARTED:
        return {
          ...state,
          getUserLoading: true,
        };
      case types.GET_USERS_SUCCESSFUL:
        return {
          ...state,
          getUserLoading: false,
          shopUsers: action.payload,
        };
      case types.GET_USERS_FAILED:
        return {
          ...state,
          getUserLoading: false,
          error: action.payload,
        };
      case Actions.POST_USER_STARTED:
        return {
          ...state,
          newUserLoading: true,
        };
      case types.POST_USER_SUCCESSFUL:
        return {
          ...state,
          newUserLoading: false,
          newUser: action.payload,
        };
      case types.POST_USER_FAILED:
        return {
          ...state,
          newUserLoading: false,
          error: action.payload,
        };
      default: {
        return state;
      }
    }
  }
);

export default userData;
