import {
  POST_USER_STARTED,
  POST_USER_SUCCESSFUL,
  POST_USER_FAILED,
  GET_USERS_STARTED,
  GET_USERS_SUCCESSFUL,
  GET_USERS_FAILED,
} from "../types";
import {
  shopAddUser,
  getUsers,
  getElbetUsers,
  getCashierUsers,
  getAllOnlineUsers,
} from "../../Services/apis";
import { ErrorPopUp, SuccessPopUp } from "../../Utils/toastify";

export const addUserStarted = () => {
  return {
    type: POST_USER_STARTED,
  };
};

export const addUserSuccess = (payload) => {
  return {
    type: POST_USER_SUCCESSFUL,
    payload,
  };
};

export const addUserFailed = (payload) => {
  return {
    type: POST_USER_FAILED,
    payload,
  };
};

export const getUserStarted = () => {
  return {
    type: GET_USERS_STARTED,
  };
};

export const getUserSuccess = (payload) => {
  return {
    type: GET_USERS_SUCCESSFUL,
    payload,
  };
};

export const getUserFailed = (payload) => {
  return {
    type: GET_USERS_FAILED,
    payload,
  };
};

export const addUser = (payload) => async (dispatch) => {
  try {
    dispatch(addUserStarted());
    const response = await shopAddUser(payload);
    SuccessPopUp(response?.message);
    return dispatch(addUserSuccess(response.message));
  } catch (error) {
    ErrorPopUp(error?.response?.data?.message);
    return dispatch(addUserFailed(error?.response?.data?.message));
  }
};

export const getShopUsers = (id) => async (dispatch) => {
  try {
    dispatch(getUserStarted());
    const response = await getUsers(id);
    if (response.success) {
      return dispatch(getUserSuccess(response.data));
    } else {
      ErrorPopUp(response.message);
      return dispatch(getUserFailed(response.message));
    }
  } catch (error) {
    ErrorPopUp(error.response.message);
    return dispatch(getUserFailed(error.response));
  }
};

export const getElbets = (user) => async (dispatch) => {
  try {
    dispatch(getUserStarted());
    const response = await getElbetUsers(user);
    return dispatch(getUserSuccess(response.data));
  } catch (error) {
    ErrorPopUp(error.response.message);
    return dispatch(getUserFailed(error.response.message));
  }
};
