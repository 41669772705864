import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCrosshairs,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate, formatOdd } from "../../Utils/helpers";
import { useEffect, useState } from "react";
import { addToCoupon } from "../../Redux/actions";
import { createID } from "../../Utils/couponHelpers";
import { update } from "lodash";

function ViewFixtureModal({ fixture, setShow, dispatch, updateCode }) {
  const [markets, setMarkets] = useState(fixture?.markets);
  useEffect(() => {
    if (fixture) {
      // if (fixture.fixture_type === "pre") {
      setMarkets(fixture.markets?.sort((a, b) => a.marketID - b.marketID));
      // } else {
      //   setMarkets(fixture?.live_data?.markets?.sort((a, b) => a.id - b.id));
      // }
    }
    return () => setMarkets("");
  }, [fixture]);

  const searchMarket = (e) => {
    const val = e.target.value;
    if (val === "") {
      setMarkets(fixture?.markets);
      return;
    }
    const filtered = markets.filter((market) =>
      market.marketName.includes(val.charAt(0).toUpperCase() + val.slice(1))
    );
    if (filtered.length) {
      setMarkets(filtered);
    } else {
      setMarkets(fixture?.markets);
    }
  };

  const addSelection = (selection, market) => {
    try {
      if (selection) {
        const id = createID(
          fixture.matchID,
          market.marketID,
          selection.outcomeName,
          selection.outcomeID
        );
        dispatch(
          addToCoupon({ ...fixture, ...market }, selection, id, "prematch")
        );

        if (update) updateCode(selection, market);
      }
      setShow(false);
    } catch (error) {}
  };

  const PreMatchMarkets = ({ market }) => {
    return (
      <div className="event-container opened odd" key={market.marketID}>
        <div className="subHeader">
          <div className="headerText">
            <span>{market.marketName}</span>
          </div>
        </div>
        <div
          ng-className="{'wrap-6' : oddsCollection.MatchOdds.length > 6}"
          className={`content ${market?.outcomes?.length > 6 ? "wrap-6" : ""}`}
        >
          {market.outcomes?.map((selection, i) => (
            <div
              className="inner-content eventPopup"
              key={`selection_${market.marketName}_${selection.oddID}_${i}`}
            >
              <span>{selection.oddName}</span>
              <div
                style={{ whiteSpace: "nowrap" }}
                className="innerOddItem make-offering"
                title="1"
              >
                <a
                  href="javascript:;"
                  onClick={() => addSelection(selection, market)}
                >
                  <div className="oddBorder">{selection.odds.toFixed(2)}</div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const LiveMatchMarkets = () => {
    const selectOdds = (market, selection) => {
      dispatch(
        addToCoupon(
          fixture,
          market.id,
          market.name,
          selection.odds,
          selection.type + "_" + market.id,
          selection.type,
          createID(
            fixture.provider_id,
            market.id,
            selection.type,
            selection.type + "_" + market.id
          ),
          "live"
        )
      );

      if (update) updateCode(selection, market);

      setShow(false);
    };

    return markets.map(
      (market, i) =>
        market.active === "1" && (
          <div className="event-container opened odd" key={market.marketID}>
            <div className="subHeader">
              <div className="headerText">
                <span>
                  {market.marketName}{" "}
                  {market.specialOddsValue && market.specialOddsValue !== "-1"
                    ? market.specialOddsValue
                    : ""}
                </span>
              </div>
            </div>
            <div
              ng-className="{'wrap-6' : market.odds.length > 6}"
              className={`content ${
                market?.outcomes?.length > 6 ? "wrap-6" : ""
              }`}
            >
              {market.outcomes.map((selection, i) => (
                <div
                  className="inner-content eventPopup"
                  key={`selection_${market.marketName}_${selection.displayName}_${i}`}
                >
                  <span>{selection.displayName}</span>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="innerOddItem make-offering"
                    title="1"
                  >
                    <a
                      href="javascript:;"
                      onClick={() => selectOdds(market, selection)}
                    >
                      <div className="oddBorder">
                        {formatOdd(parseFloat(selection.odds))}
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
    );
  };

  return (
    <>
      <div className="match-offerings-panel">
        <div className="panel panel-custom no-brand-footer">
          <div className="panel-heading w-icon panel-heading-green">
            <h3 className="panel-title">
              <FontAwesomeIcon
                icon={faCrosshairs}
                className="fa-fw"
                size="2x"
              />
              Match Offerings
            </h3>
            <span className="close-button" onClick={() => setShow()}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </span>
          </div>
          <div className="panel-body">
            <div className="top-bar">
              <div className="match-info-section">
                <div className="match-date-info">
                  <span className="match-date">
                    {formatDate(fixture?.date, "DD/MM/YYYY")}
                  </span>
                  <span className="match-time">{fixture?.date}</span>
                </div>
                <div className="match-id">{fixture?.matchID}</div>
                <div className="match-details">
                  <span className="tournament-name">
                    {fixture?.sportName} / {fixture?.categoryName} /{" "}
                    {fixture?.tournament}
                  </span>
                  <span className="match-name">{fixture?.name}</span>
                </div>
              </div>
              <div className="filter-section">
                <div>
                  <span className="filter-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                  <input
                    type="text"
                    onKeyUp={(e) => searchMarket(e)}
                    className="filter-box"
                    placeholder="Filter Odds Type"
                  />
                </div>
              </div>
            </div>

            <div className="results">
              <div className="results-wrapper mCustomScrollbar _mCS_20 mCS-autoHide">
                <div className="allMarkets">
                  {markets?.map((market, i) => (
                    <PreMatchMarkets market={market} key={`market-${i}`} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="match-offerings-overlay" onClick={() => setShow()} />
    </>
  );
}

export default ViewFixtureModal;
